import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { Heading } from '../components/Heading/Heading';
import * as styles from './index.module.scss';

// markup
const NotFoundPage = () => {
  return (
    <Layout is404={true}>
      <title>Not found</title>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-8 col-lg-4'>
            <div className={styles.container404}>
              <Heading
                tagName={'h2'}
                styleType={'h2'}
                textAlignment={'center'}
                textColour={'primary'}
              >
                Désolé, la page que vous cherchez n'existe pas.
              </Heading>
              <br />
              <Link to='/' className={styles.buttonPhoneCall}>
                Cliquez ici pour revenir sur la page d'accueil
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
